import React from 'react'
import { useParams } from "react-router-dom";
import DistrictListDetailsCard from '../Component/DistrictListDetailsCard'
import { useEffect, useState } from "react";
import axios from 'axios';
import { baseUrl,adminUrl } from '../Component/utils/config.js';


const DistrictDetailsList = () => {
    let { districtId } = useParams();
    const [legislatorData, setLegislatorData] = useState([]);

    useEffect(() => {

        axios.get(`${adminUrl}admin/federaldistrict/getDistrictListCard/${districtId}`)
            .then(response => {
                // Set the data in state
                // const [districtList, MunicipalName, MunicipalID] = response.data;
                setLegislatorData(response.data);
            })
            .catch(error => {
                console.error("Error fetching legislator data:", error);
            });
    }, [districtId]);
    const queryString = districtId;
    const numericPart = queryString.split('-')[1];
    return (
        <section class="bmainpart">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="listingbox">
                            <div class="headingbox">
                                <h2>District {numericPart}</h2>
                            </div>
                        </div>
                    </div>
                    {legislatorData.map(legislator => (
                        <DistrictListDetailsCard key={legislator.id} legislator={legislator} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default DistrictDetailsList
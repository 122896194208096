import { useEffect, useState } from "react"
import '../Assets/Css/Popup.css'
import { Link,useNavigate  } from "react-router-dom";
export const Popup = () => {
    const navigate = useNavigate();
    const [alertState, setAlertState] = useState();
    useEffect(() => {
        const fn = (event) => {
            setAlertState({ senator1: event.detail.senator1, senator2: event.detail.senator2, userrep: event.detail.userrep, objID: event.detail.objID, DisTrictname: event.detail.DisTrictname });
        }
        window.addEventListener('popup', fn)
        return () => window.removeEventListener('popup', fn)
    }, [])
    const onPopupClose = () => {
        setAlertState(undefined);
    }
    const onPopupCloseAndNavigate = (districtId) => {
        setAlertState(undefined);
        navigate(`/legislative/district-${districtId}`);
      };
    return (alertState && <div id={`popbox${alertState.objID}`} className="popboxmain"><div className="mainDiv" >
        <div className="popdetails">
            <h2> {alertState.DisTrictname} </h2>
            <p><span>Senator</span> {alertState.senator1}</p>
            <p><span>Senator</span> {alertState.senator2}</p>
            <p><span>Representative</span> {alertState.userrep}</p>
            <h5 onClick={onPopupClose}>X</h5>
            <br></br>

            {/* <Link onClick={() => onPopupCloseAndNavigate(alertState.objID)} className="dstrdtls" to={`legislative/district-${alertState.objID}`}>See Details</Link> */}

            <Link onClick={() => onPopupCloseAndNavigate(alertState.objID)} className="dstrdtls" to={`districts/district-${alertState.objID}`}>See Details</Link>
        </div>
    </div></div>)
}
export const myPopup = {
    open: (senator1, senator2, userrep, objID, DisTrictname) => {
        // console.log("Souvik");
        const selectionFired = new CustomEvent("popup", {
            "detail": { senator1, senator2, userrep, objID, DisTrictname }
        });
        window.dispatchEvent(selectionFired);

    }
    
}
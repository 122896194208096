import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { myPopup } from './Popup';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
// import SearchMap from './SearchMap';
const SixMap = () => {

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1IjoiZ2V0YWN0aXZlbmoiLCJhIjoiY2xsNWhtNWJ1MGVvNTNkb3p6enBhb2lqcCJ9.eiI57yla0BXZRlvtKmQ0Gg';
        const map = new mapboxgl.Map({
            container: 'map',
            // style: 'mapbox://styles/getactivenj/clll7fl0s005c01o18p81cm8g',
            style: 'mapbox://styles/getactivenj/clljhtuq5017i01pidfrh5903',

            center: [-74.381120614147804, 40.409128270198401],
            minZoom: 2,
            zoom: 7,

        });
        // map.scrollZoom.disable();
        map.on('load', () => {
            // Rest of your code for adding source and layers...
        });
        map.addControl(
            new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                countries: 'US',
                bbox: [-75.559614, 38.928524, -73.893979, 41.357423],

                filter: function (item) {
                    // returns true if item contains New South Wales region
                    return item.context.some((i) => {
                        // ID is in the form {index}.{id} per https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
                        // This example searches for the `region`
                        // named `New South Wales`.
                        return (
                            i.id.split('.').shift() === 'region' &&
                            i.text === 'New Jersey'
                        );
                    });
                },
                mapboxgl: mapboxgl
            })
        );
        const popup = new mapboxgl.Popup({
            closeButton: false,
            closeOnClick: false
        });

        map.on('click', (e) => {
            const clickedFeatures = map.queryRenderedFeatures(e.point);
            if (clickedFeatures.length > 0) {
                const clickedLayerId = clickedFeatures[0].layer.id;
                const clickedLayerProperties = clickedFeatures[0].properties;
                const propertiesString = JSON.stringify(clickedLayerProperties, null, 2);
                // console.log(Clicked Layer ID: ${clickedLayerId}\nProperties:\n${propertiesString});
                // alert(Clicked Layer ID: ${clickedLayerId}\nProperties:\n${propertiesString});
                const properties = JSON.parse(propertiesString);
                if (properties.SENATOR1 && properties.SENATOR2 && properties.US_REP) {
                    myPopup.open(properties.SENATOR1, properties.SENATOR2, properties.US_REP, properties.DISTRICT, properties.DIST_LABEL);
                }
                // myPopup.open(properties.ASSEMBLY1,properties.ASSEMBLY2,properties.SENATE);
            }
        });
        map.on('click', () => {
            popup.remove();
        });

        return () => {
            map.remove();
        };
    }, []);
    const popup = new mapboxgl.Popup({
        anchor: 'bottom', // Try different anchor values
        closeButton: false,
        closeOnClick: false
    });

    // return <div id="map" style={{ position: 'absolute', top: 0, bottom: 0, width: '100%' }} />;
    return (
        <>
            <section className="bmainpart">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 text-center searchfont">
                            <h1>Find your district by exploring our map or entering in your address. Click on a district for more info.</h1>
                            {/* <clickedFeatures/> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* <SearchMap /> */}
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-10 offset-1 mob-lm-0'>
                        <div id="map" style={{ top: 0, bottom: 0, width: '100%', height: '500px' }} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default SixMap;

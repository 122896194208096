import { Popup } from "./Component/Popup";
import SixMap from "./Component/SixMap";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DistrictList from "./Page/DistrictList";
import DistrictDetails from "./Page/DistrictDetails";
import Header from "./Component/Common/Header";
import Footer from "./Component/Common/Footer";
import DistrictDetailsList from "./Page/DistrictDetailsList";
function App() {
  return (
    <>
      <Header/>
      <Router>
        <div className="App">
          <Popup />
          <Routes>
            <Route path="/" element={<SixMap />} />
            {/* <Route path='/pop' element={<Popup/>} /> */}
            {/* <Route path='/distric-details' element={<DistrictDetails/>} /> */}
            <Route
              path="/legislative/:districtId"
              element={<DistrictList />}
            />
            {/* /district/:districtId/:slug */}
            {/* /district/24/legislator */}
            <Route
              path="/district/:districtId/:legislatorSlug"
              element={<DistrictDetails />}
            />
            {/* https://www.njleg.state.nj.us/districts/district-24 */}
            <Route
              path="/districts/:districtId"
              element={<DistrictDetailsList />}
            />
            
          </Routes>
        </div>
      </Router>
      <Footer/>
    </>
  );
}

export default App;

export const baseUrl = "https://federalmaps.getactivenj.com/";

export const adminUrl = "https://mcms.getactivenj.com/";

export const maillingUrl = "https://mailing.getactivenj.com";



export const intialDat = [
        {
            "id": "49",
            "orderid": "0",
            "DistrictID": "24",
            "RegistrationName": "Senator Steven V. Oroho (R)",
            "slug": "senator-steven-v-oroho-R",
            "House": "LEGISLATOR ",
            "Party": "LEGISLATOR ",
            "Born": "April 12, 1962",
            "Education": "B.A. Lycoming College (Managerial Economics\/Business Administration) J.D. Seton Hall University School of Law", "Occupation": "Attorney, Murphy and McKeon,P.C.",
            "Fax": "-",
            "featured_image": "oroho_steven_2022_250.jpg",
            "Position": "LEGISLATOR ",
            "OfficeAddress": "75 Bloomfield Ave., Suite 302, 3rd Floor Denville, NJ 07834",
            "PhoneNumber": "(973) 627-9700",
            "FaxNumber": "(973)300-1744",
            "meta_title": "LEGISLATOR ",
            "meta_keyword": "LEGISLATOR ",
            "meta_descrip": "LEGISLATOR ",
            "canonical_tag": "LEGISLATOR",
            "robot_index": "index",
            "follow": "follow",
            "created_on": "2023-09-14",
            "created_by": "0",
            "modified_on": null,
            "modified_by": "0",
            "status": "0",
            "Extra1":"<p>B.A. East Stroudsburg (Political Science)</p><p>J.D. Seton Hall University</p>",
            "Extra2" : "Education (SED)<br />Economic Growth (SEG)<br />Judiciary (SJU)<br />Transportation (STR)",
            
        }];